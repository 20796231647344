<div class="main">
  <div class="biolife-logo">
    <img
      class="m-5"
      width="319"
      height="166"
      src="../../../../../assets/img/biolife_logo_350w.png"
      alt="image"
    />
    <div class="container justify-content-center d-flex flex-column">
      <button class="btn btn-light shadow-sm btn-lg" (click)="oktaAuth.login('/callback')">
        <img alt="okta-img" class="okta-img" src="../../../../../assets/img/okta_o.svg" /> Sign In With Okta
      </button>
    </div>
    <div class="versioning">
      Version: {{ version }}
    </div>
  </div>
</div>
