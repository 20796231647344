import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallbackComponent } from './components/callback/callback.component';

@NgModule({
  declarations: [
    CallbackComponent
  ],
  imports: [
    CommonModule
  ]
})
export class CoreModule { }
