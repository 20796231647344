import { Component,Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-nurse-login',
  templateUrl: './nurse-login.component.html',
  styleUrls: ['./nurse-login.component.scss']
})
export class NurseLoginComponent {
  constructor(public oktaAuth: AuthService) {}
  version = environment.applicationVersion;
}
