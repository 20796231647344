import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Component({ template: `` })
export class CallbackComponent implements OnInit {
  constructor(private okta: AuthService) {}

  ngOnInit(): void {
    // Handles the response from Okta and parses tokens
    // this.okta.handleAuthentication();
  }
}
