import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './modules/core/components/callback/callback.component';
import { NurseLoginComponent } from './modules/login/components/nurse-login/nurse-login.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { errorPageComponent } from './modules/shared/components/error-page/error-page.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: NurseLoginComponent },
  { path: 'nurse-homepage', component: OktaCallbackComponent },
  // {
  //   path: 'callback',
  //   loadChildren: () => import('./modules/homepage/homepage.module').then(m => m.HomepageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'callback', loadChildren: () => import('./modules/homepage/homepage.module')
    .then(m => m.HomepageModule)
  },
  {
    path: 'access-denied',
    component: errorPageComponent,
    data: {
      errorCode: '403',
      errorMessage: 'Access Denied'
    }
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
