import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map, Observable, Observer } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  isAuthenticated$: Observable<boolean>;
  private observer: Observer<boolean>;

  constructor(private _router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _oktaStateService: OktaAuthStateService
  ) {
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
  }


  async isAuthenticated(): Promise<boolean> {
    console.log('Access Token', this._oktaAuth.tokenManager.get('accessToken'));
    
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this._oktaAuth.tokenManager.get('accessToken'));
  }

  async getUserData(): Promise<any> {
    const userData = await this._oktaAuth.getUser();
    return { userData };
  }

  // login(originalUrl: any): void {
  //   // Save current URL before redirect
  //   sessionStorage.setItem('okta-app-url', originalUrl || this.router.url);

  //   // Launches the login redirect.
  //   this.oktaAuth.token.getWithRedirect({
  //     scopes: ['openid', 'email', 'profile'],
  //   });
  // }
  login(originalUrl: any): void {
    // Save current URL before redirect
    sessionStorage.setItem('okta-app-url', originalUrl || this._router.url);

    // Launches the login redirect.
    this._oktaAuth.signInWithRedirect({
      scopes: ['openid', 'email', 'profile'],
    }).then(
      _ => this._router.navigate(['/callback'])
    );
  }

  async handleAuthentication(): Promise<void> {
    // const tokenContainer = await this.oktaAuth.token.parseFromUrl();

    // this.oktaAuth.tokenManager.add('idToken', tokenContainer.tokens.idToken!);
    // this.oktaAuth.tokenManager.add('accessToken', tokenContainer.tokens.accessToken!);

    // if (await this.isAuthenticated()) {
    //   this.observer.next(true);
    // }
    // // Retrieve the saved URL and navigate back
    // const url = sessionStorage.getItem('okta-app-url');

    // if (url === '/nurse-dashboard') {
    //   await this.router.navigateByUrl(url);
    // } else {
    //   this.document.location.href = environment.webex_redirect_url;
    // }
  }
}
