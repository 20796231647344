import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private tokenExtractor: HttpXsrfTokenExtractor
  ) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    switch (err.url) {
      case environment.login_proxy_url:
        // Failed nurse credential submission during call to save donor consent data
        // Do not redirect, otherwise call will be terminated
        break;

      default:
        // if (err.status === 401) {
        //   this.router.navigateByUrl(`/login`)
        //     .catch(navErr => {
        //       console.error('Unable to navigate to login page after 401 error\n', navErr);
        //     });
        // } else if (err.status === 403) {
        //   this.router.navigateByUrl(`/access-denied`)
        //     .catch(navErr => {
        //       console.error('Unable to navigate to 403 Error page\n', navErr);
        //     });
        // }
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req.clone();

    const extractedDomain = (req.url.trim().match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/) || [])[0];
    const allowedDomains = JSON.parse(environment.whitelisted_domains || '[""]');
    // Only whitelisted domains can receive JWT
    if (allowedDomains.includes(extractedDomain)) {
      let idToken;
      if(localStorage.getItem('okta-token-storage')){
        idToken = JSON.parse(localStorage.getItem('okta-token-storage')!).idToken;
      } else {
        idToken = 'eyJraWQiOiI3WWFacmpNcy03T1FYWXhOeHZ2eVZ5QWx6QWpqVDY2alNWeUZURGM2N25vIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVtZGg2cWJpQ3F2SFBsVTM1NyIsIm5hbWUiOiJNYW5zbyBHb256w6FsZXosIFBhYmxvIiwiZW1haWwiOiJwYWJsby5tYW5zby1nb256YWxlekB0YWtlZGEuY29tIiwidmVyIjoxLCJpc3MiOiJodHRwczovL3Rha2VkYS5va3RhLmNvbS9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6IjBvYXBnNG9jbzZOQldhbGdBMzU3IiwiaWF0IjoxNjgwNTg2OTczLCJleHAiOjE2ODA1OTA1NzMsImp0aSI6IklELnloYmpteGhVSnJySlpxNlhZT0FuNzBCS25GNGhBMFE2enB0NnB6WTAxOFkiLCJhbXIiOlsicHdkIl0sImlkcCI6IjAwb3UxbGhua0NXMThxRzFRMzU2Iiwibm9uY2UiOiJ2b29IdnEwZjVUWlJmWXJONlU2WW9OVkdWYlM5TUlGcmdadUk0UG9FNVRpWVZkaFF1QjVLcjVLUXd1NEJ3eTQ3IiwicHJlZmVycmVkX3VzZXJuYW1lIjoicGFibG8ubWFuc28tZ29uemFsZXpAdGFrZWRhLmNvbSIsImF1dGhfdGltZSI6MTY4MDU4Njk3MSwiYXRfaGFzaCI6IlU1RkdBUkFXdzNGRlZseC1iWEwtd1EifQ.m1a_Qrj1KbySY-YCZKFe2lw98luQWqd_HRsMlh9gOQN6ZeoflBqMm7HWyNwXp5KhzguUDGvqPzD4ULqgtx_uMJ0CRKykXvRTayt8duUXnMCev7W9nlT4ZJ9Z10q6kPNG6PwqpcBqSN4SUhz6eC1UScJNqnriUd9-yT22eaJlu4dqoNp6XM9AvNAwsF0A8rv6t7wMRHhWAGHAdTebj90veg18GeVYhNdo-dvfjwFzpYwAH6Ox2o-ioAZdLtVDXZKj1Zm8jdLNpJqdy9I8e6RLq3ZLWGKOa1r2OoreJXoEbUlP3OVN7vL2IVx8ppBv6XC27Vh101mhs72pe8YOX46s_g';
      }
      if (idToken) {
        let headers = req.headers;
        if (!headers.has('Authorization')) {
          headers = headers.set('Authorization', `Bearer ${idToken.idToken}`);
        }

        const xsrfToken = this.tokenExtractor.getToken();
        if (xsrfToken) {
          headers = headers.set('X-XSRF-TOKEN', xsrfToken);
        }

        request = req.clone({
          headers
        });
      }
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }
}
