import packageJson from '../../package.json';

export const environment = {
  production: false,
  consent_flow: true,
  applicationVersion: packageJson.version,
  client_secret:
    'f1784bc5ca23d0bb2cd0b7e90da217c3138303df2ea44db7bcdd43c8fae5dde2',
  client_id:
    'C500d28d02c74e59444545c60d718ecaa440d6e9cf78e096ef331e45ff8ebe2c2', // webex
  connect_nurse_dashboard_url:
    'https://0f17s8u957.execute-api.us-east-1.amazonaws.com/dev/donorsinqueue',
  nurse_x_api_key: 'o4Y3rrGKRg2iipAjv8CmOaYuhnlDUyMK5IRJESB8', // pdt-mss-connect-dashboard-api-key
  webex_redirect_url:
    'https://webexapis.com/v1/authorize?client_id=C500d28d02c74e59444545c60d718ecaa440d6e9cf78e096ef331e45ff8ebe2c2&response_type=code&redirect_uri=https%3A%2F%2Fdev-mssr-nurse.takeda.com%2Fcallback&scope=spark%3Aall%20spark%3Akms&state=set_state_here',
  connect_instance_url: 'https://pdt-dev.awsapps.com/connect/ccp-v2',
  ccp_loginUrl:
    'https://takeda.okta.com/home/amazon_aws/0oa5a3jqgqQgFI5du357/272?fromHome=true',
  consent_submission_url:
    'https://mjei7w5ipb.execute-api.us-east-1.amazonaws.com/dev/consent',
  consent_submission_x_api_key: 'lKezwHEwWO7hNkjPbnT6U8SKG6jbevx34w6kaqdW', // consent-submission-key
  login_proxy_url:
    'https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com/dev/login',
  queue_size_api_url:
    'https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com/dev/queue',
  webex_access_token_url: 'https://webexapis.com/v1/access_token',
  x_api_key: 'HLFUUiz3YY8Mz2l8pS6rf510iNa6UPz046Vletcv', // mssapikey
  ws_ticket_url: 'https://01oxq9mtk2.execute-api.us-east-1.amazonaws.com/dev',
  okta: {
    clientId: '0oa5dbjrtxp2Uo633357',
    issuer: 'https://takeda.okta.com/oauth2/default',
    redirectUri: 'https://dev-mssr-nurse.takeda.com/nurse-homepage',
    pkce: true,
  },
  wsEndpoint: 'wss://3d0f9bceyl.execute-api.us-east-1.amazonaws.com/dev',
  ws_gateway_name: 'PDTMSSWebSocketDashboard',
  reconnectInterval: 2000,
  whitelisted_domains: '[' +
    '"https://0f17s8u957.execute-api.us-east-1.amazonaws.com",' +
    '"https://mjei7w5ipb.execute-api.us-east-1.amazonaws.com",' +
    '"https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com",' +
    '"https://01oxq9mtk2.execute-api.us-east-1.amazonaws.com"' +
    ']'
};