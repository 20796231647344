import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-error-page',
  template: `
    <div class="error-wrap">
      <h1 class="error-code">{{errorCode}}</h1>

      <p class="error-message">{{errorMessage}}</p>
    </div>
  `,
  styles: [
    `
      .error-wrap{
        text-align: center;
        padding: 10px;
      }

      .error-code {

      }

      .error-message {
        font-size: 18px;
      }
    `
  ]
})
export class errorPageComponent implements OnInit {
  errorCode = '';
  errorMessage = 'Something went wrong!';


  constructor(private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.errorCode = data['errorCode'] || this.errorCode;
      this.errorMessage = data['errorMessage'] || this.errorMessage;
    });
  }
}
