import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from './modules/shared/shared.module';
import { CoreModule } from './modules/core/core.module';

import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { JwtInterceptor } from './modules/core/interceptors/jwt.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import {
  APP_INITIALIZER,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import OktaAuth from '@okta/okta-auth-js';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';


const oktaAuth = new OktaAuth({
  issuer: environment.okta.issuer,
  redirectUri: environment.okta.redirectUri,
  clientId: environment.okta.clientId,
  pkce: environment.okta.pkce,
})

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    OktaAuthModule,
    SharedModule,
    CoreModule,
    MatButtonModule,
    MatDialogModule,
    AngularSvgIconModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: OKTA_CONFIG, useValue: {oktaAuth} },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
